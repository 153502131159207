import { useState, useCallback, useEffect } from 'react';
import { HomePageSections } from 'view/pages/Home';

export type SectionState = {
    current : number;
    status  : {
        [ key : number ] : string;
    }
};

type SetSectionState = React.Dispatch<React.SetStateAction<SectionState>>;
type SetCanScrollUp = React.Dispatch<React.SetStateAction<boolean>>;
type SetCanScrollDown = React.Dispatch<React.SetStateAction<boolean>>;

export const useSegmentedScroll = () : [ SectionState, SetSectionState, SetCanScrollUp, SetCanScrollDown ] => 
{ 
    const min = 0; // These could be passed in for greater generality, but fine to just hardcode for now.
    const max = 5;
    const [ semaphore, setSemaphore ] = useState<boolean>(true);
    const [ canScrollUp, setCanScrollUp ] = useState<boolean>(true);
    const [ canScrollDown, setCanScrollDown ] = useState<boolean>(false);
    const [ sectionState, setSectionState ] = useState<SectionState>({
        current : 0,
        status : {
            0 : 'non-offset',
            1 : 'non-offset',
            2 : 'non-offset',
            3 : 'non-offset',
            4 : 'non-offset',
            5 : 'non-offset' // This should always be `non-offset`
        }
    });

    const computeClassNames = useCallback(direction => 
    {
        setSectionState(state => 
        {
            /* eslint-disable prefer-const */
            let { current, status } = state;
            if (direction === 1 && current !== max) 
            {
                if (current > min) status[current - 1] = 'offset';
                status[current] = 'translate-up';
                current += 1;
            } 
            else if (direction === -1 && current !== min) 
            {
                current -= 1;
                status[current] = 'translate-down';
            }
            return {
                current,
                status
            };
        });
    }, [ setSectionState ]);

    const unlockResources = () => setSemaphore(true);

    const lockResources = useCallback((timeout? : number) => 
    {
        setSemaphore(false);
        setTimeout(() => unlockResources(), timeout ?? 1250);
    }, [ setSemaphore ]);

    useEffect(() => 
    {
        let yDown : number | null = null;

        const handleTouchStart = (event : TouchEvent) => 
        {
            const firstTouch = event.touches[0];                                                                        
            yDown = firstTouch.clientY;                                      
        }; 

        const handleTouchMove = (event : TouchEvent) => 
        {
            if (!yDown) return;

            const yUp = event.touches[0].clientY;
            const yDiff = yDown - yUp;

            if (semaphore) 
            {
                lockResources();
                // Compute new className strings on scroll up/down
                if(yDiff > 0) computeClassNames(1);
                else computeClassNames(-1);
            }        

            yDown = null;                                             
        };

        const wheel = (event : WheelEvent) => 
        {
            if (semaphore) 
            { 
                lockResources();
                // Compute new className strings on scroll up/down
                if (isScrollingUp(event)) 
                {
                    if (sectionState.current === HomePageSections.SERVICES) // hardcoded to check the services serction
                    {
                        if (canScrollUp) 
                        {

                            computeClassNames(-1);
                        }
                    }
                    else 
                    {
                        computeClassNames(-1);

                    }
                }
                else 
                {
                    if (sectionState.current === HomePageSections.SERVICES) // hardcoded to check the services serction
                    {
                        if (canScrollDown) 
                        {

                            computeClassNames(1);
                        }
                    }
                    else 
                    {
                        computeClassNames(1);

                    }
                }
            }
        };

        const keydown = (event : KeyboardEvent) => 
        {
            if (semaphore) 
            {
                switch (event.key) 
                {
                case 'ArrowUp':
                {
                    lockResources(100);
                    computeClassNames(-1);

                    break;
                }
                case 'ArrowDown':
                {
                    lockResources(100);
                    computeClassNames(1);

                    break;
                }
                }
            }
        };

        window.addEventListener('wheel', wheel);
        window.addEventListener('touchstart', handleTouchStart);        
        window.addEventListener('touchmove', handleTouchMove);
        window.addEventListener('keydown', keydown);
        return () => 
        {
            window.removeEventListener('wheel', wheel);
            window.removeEventListener('touchstart', handleTouchStart);
            window.removeEventListener('touchmove', handleTouchMove);
            window.removeEventListener('keydown', keydown);
        };
    }, [ semaphore, setSemaphore, setSectionState, computeClassNames ]);

    return [ sectionState, setSectionState, setCanScrollUp, setCanScrollDown ];
};

function isScrollingUp(event : WheelEvent) 
{
    return event.deltaY < 0;
}