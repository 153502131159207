import React from 'react';
import { AuthAction, AuthPayload, AuthType } from './reducer';

type Props = {
    dispatch : React.Dispatch<AuthAction>;
    payload? : AuthPayload;
};

export const login = async ({ dispatch, payload } : Props) : Promise<void> => 
{
    const data : AuthPayload = {
        id    : '',
        token : '',
        ...payload
    };

    dispatch({ type: AuthType.LOGIN_SUCCESS, payload: data });
};

export const logout = async ({ dispatch } : Props) : Promise<void> =>
{
    dispatch({ type: AuthType.LOGOUT, payload: { id: '', token: '' } });
};