import { useEffect } from 'react';

declare global {
    interface Window {
        usePreloadImagesData?: Record<string, Array<unknown>>;
    }
}

const uuidv4 = () => 
{
    return '10000000-1000-4000-8000-100000000000'
        .replace(/[018]/g, d => 
        {
            const digit = Number(d);
            return (digit ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> digit / 4).toString();
        });
};

export const useImagePreload = (images: Array<string>) : void =>
{
    useEffect(() => 
    {
        const uuid = uuidv4();
        window.usePreloadImagesData = window.usePreloadImagesData ?? {};
        window.usePreloadImagesData[uuid] = [];

        for (const src of images)
        {
            const img = new Image();
            img.src = src;
            window.usePreloadImagesData[uuid].push(img);
        }

        return () => 
        {
            delete window.usePreloadImagesData?.[uuid];
        };
    }, [ images ]);
};