import React, { useEffect, useRef } from 'react';

export const useHorizontalScrollHandler = (scrollContainerRef: React.RefObject<HTMLDivElement>): void => 
{
    const lastTouchYRef = useRef<number | null>(null);

    useEffect(() => 
    {
        let isContainerInView = false;

        const observer = new IntersectionObserver(
            (entries) => 
            {
                entries.forEach(entry => 
                {
                    isContainerInView = entry.isIntersecting;
                });
            },
            { threshold: 1 }
        );

        if (scrollContainerRef.current) 
        {
            observer.observe(scrollContainerRef.current);
        }

        const handleScroll = (e: WheelEvent | TouchEvent | KeyboardEvent) => 
        {
            const scrollContainer = scrollContainerRef.current as HTMLDivElement;
            const { scrollLeft, clientWidth, scrollWidth } = scrollContainer;

            const isStart = scrollLeft === 0;
            const isEnd = scrollLeft + clientWidth >= scrollWidth;

            if (isContainerInView) 
            {
                let deltaY = 0;

                if (e instanceof WheelEvent) 
                {
                    deltaY = e.deltaY;
                }
                else if (e instanceof TouchEvent) 
                {
                    const touch = e.touches[0];
                    if (lastTouchYRef.current === null) 
                    {
                        lastTouchYRef.current = touch.clientY;
                    }
                    deltaY = lastTouchYRef.current - touch.clientY;
                    lastTouchYRef.current = touch.clientY;
                }
                else if (e instanceof KeyboardEvent) 
                {
                    if (e.key === 'ArrowDown' || e.key === 'ArrowUp') 
                    {
                        deltaY = e.key === 'ArrowDown' ? 100 : -100;
                    }
                }

                if ((scrollLeft + clientWidth < scrollWidth && deltaY > 0) || (scrollLeft > 0 && deltaY < 0)) 
                {
                    if (!(isStart || isEnd)) 
                    {
                        e.preventDefault();
                    }
                    scrollContainer.scrollLeft += deltaY;
                }
            }
        };

        const handleKeydown = (e: KeyboardEvent) => 
        {
            if ([ 'ArrowDown', 'ArrowUp' ].includes(e.key)) 
            {
                handleScroll(e);
            }
        };

        const handleTouchStart = (e: TouchEvent) => 
        {
            const touch = e.touches[0];
            lastTouchYRef.current = touch.clientY;
        };

        window.addEventListener('wheel', handleScroll, { passive: false });
        window.addEventListener('touchmove', handleScroll, { passive: false });
        window.addEventListener('touchstart', handleTouchStart, { passive: true });
        window.addEventListener('keydown', handleKeydown);

        return () => 
        {
            window.removeEventListener('wheel', handleScroll);
            window.removeEventListener('touchmove', handleScroll);
            window.removeEventListener('touchstart', handleTouchStart);
            window.removeEventListener('keydown', handleKeydown);

            if (scrollContainerRef.current) 
            {
                observer.unobserve(scrollContainerRef.current);
            }
        };
    }, [ scrollContainerRef ]);
};